import React from 'react';
import { Switch, Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '@qwkin/core';

import { PrivateRoute } from './private';
import { asyncLoad } from '../utils/asyncLoad';

const AsyncLoginScreen = asyncLoad(() => import('../screens/login'));
const AsyncCheckinPassScreen = asyncLoad(() => import('../screens/checkinPass'));
const AsyncPage404Screen = asyncLoad(() => import('../screens/404'));
const AsyncVisitorsScreen = asyncLoad(() => import('../screens/visitors'));
const AsyncUnitListScreen = asyncLoad(() => import('../screens/units/list'));
const Async500Screen = asyncLoad(() => import('../screens/500'));
const AsyncContractorsScreen = asyncLoad(() => import('../screens/contractors/list'));
const AsyncNewContractorScreen = asyncLoad(() => import('../screens/contractors/newContractor'));
const AsyncGateFeedScreen = asyncLoad(() => import('../screens/gateFeed'));
const AsyncPackageTrackingScreen = asyncLoad(() => import('../screens/packageTracking'));
const AsyncValetScreen = asyncLoad(() => import('../screens/valet'));
const AsyncValetCustomersReport = asyncLoad(() => import('../screens/valet/valetCustomersReport'));
const AsyncNewPricingTable = asyncLoad(() => import('../screens/settings/newPricingTable'));
const AsyncEditPricingTable = asyncLoad(() => import('../screens/settings/editPricingTable'));
const AsyncCommunityScreen = asyncLoad(() => import('../screens/community'));
const AsyncCreateCommunityNoticeScreen = asyncLoad(() =>
  import('../screens/community/createCommunityNotice'),
);
const AsyncAmenitiesV2Screen = asyncLoad(() => import('../screens/amenities'));
const AsyncCreateAmenity = asyncLoad(() => import('../screens/amenities/createAmenityPage'));
const AsyncAmenitiesDetailsV2Screen = asyncLoad(() => import('../screens/amenities/amenity'));
const AsyncAmenitiesCalendarV2Screen = asyncLoad(() => import('../screens/amenities/calendar'));
const AsyncUnitV2Screen = asyncLoad(() => import('../screens/unit'));
const AsyncSettingsV2Screen = asyncLoad(() => import('../screens/settings'));
const AsyncContractorV2Screen = asyncLoad(() => import('../screens/contractor'));
const AsyncEventBadgeScreen = asyncLoad(() => import('../screens/gateFeed/eventBadge'));
const AsyncDashboardV2Screen = asyncLoad(() => import('../screens/dashboard'));
const AsyncLOLScreen = asyncLoad(() => import('../screens/lookOutList'));
const AsyncPendingCheckoutScreen = asyncLoad(() => import('../screens/pendingCheckouts'));
const AsyncPackagesMapScreen = asyncLoad(() => import('../screens/packageMap'));
const AsyncGateGameraScreen = asyncLoad(() => import('../screens/gateCamera'));
const AsyncReportsScreen = asyncLoad(() => import('../screens/reports'));
const AsyncWorkOrdersScreen = asyncLoad(() => import('../screens/workOrders'));
const AsyncDocumentsScreen = asyncLoad(() => import('../screens/documents'));
const AsyncGenerateNewUnitsReportScreen = asyncLoad(() =>
  import('../screens/reports/generateNewUnitsReportScreen'),
);
const AsyncInvoiceScreen = asyncLoad(() => import('../screens/invoice'));
const AsyncIncidentReportScreen = asyncLoad(() => import('../screens/incidentReport'));

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path="/pass/:id" component={AsyncCheckinPassScreen} />
      <Route path="/login" component={AsyncLoginScreen} />
      <PrivateRoute exact path="/" component={AsyncDashboardV2Screen} />
      <PrivateRoute exact path="/gateFeed" component={AsyncGateFeedScreen} />
      <PrivateRoute exact path="/gateFeed/eventBadge" component={AsyncEventBadgeScreen} />
      <PrivateRoute exact path="/lookOutList" component={AsyncLOLScreen} />
      <PrivateRoute exact path="/community" component={AsyncCommunityScreen} />
      <PrivateRoute
        exact
        path="/community/create/notice"
        component={AsyncCreateCommunityNoticeScreen}
      />
      <PrivateRoute exact path="/visitors" component={AsyncVisitorsScreen} />
      <PrivateRoute exact path="/units" component={AsyncUnitListScreen} />
      <PrivateRoute exact path="/unit/:id" component={AsyncUnitV2Screen} />
      <PrivateRoute exact path="/settings" component={AsyncSettingsV2Screen} />
      <PrivateRoute exact path="/settings/gateCamera" component={AsyncGateGameraScreen} />
      <PrivateRoute exact path="/contractors" component={AsyncContractorsScreen} />
      <PrivateRoute exact path="/contractor/:id" component={AsyncContractorV2Screen} />
      <PrivateRoute exact path="/amenities" component={AsyncAmenitiesV2Screen} />
      <PrivateRoute exact path="/amenities/create" component={AsyncCreateAmenity} />
      <PrivateRoute exact path="/amenities/calendar" component={AsyncAmenitiesCalendarV2Screen} />
      <PrivateRoute exact path="/amenities/:id" component={AsyncAmenitiesDetailsV2Screen} />
      <PrivateRoute
        exact
        path="/amenities/:id/:reservationId"
        component={AsyncAmenitiesDetailsV2Screen}
      />
      <PrivateRoute exact path="/create/contractor" component={AsyncNewContractorScreen} />
      <PrivateRoute exact path="/packageTracking" component={AsyncPackageTrackingScreen} />
      <PrivateRoute exact path="/valet" component={AsyncValetScreen} />
      <PrivateRoute
        exact
        path="/valet/valetCustomersReport"
        component={AsyncValetCustomersReport}
      />
      <PrivateRoute exact path="/settings/newPricingTable/:id" component={AsyncNewPricingTable} />
      <PrivateRoute exact path="/settings/editPricingTable/:id" component={AsyncEditPricingTable} />
      <PrivateRoute exact path="/checkout" component={AsyncPendingCheckoutScreen} />
      <PrivateRoute exact path="/packagesMap" component={AsyncPackagesMapScreen} />
      <PrivateRoute exact path="/reports" component={AsyncReportsScreen} />
      <PrivateRoute exact path="/workOrders" component={AsyncWorkOrdersScreen} />
      <PrivateRoute exact path="/documents" component={AsyncDocumentsScreen} />
      <PrivateRoute exact path="/invoice" component={AsyncInvoiceScreen} />
      <PrivateRoute
        exact
        path="/reports/generate/units"
        component={AsyncGenerateNewUnitsReportScreen}
      />
      <PrivateRoute exact path="/incidentReport" component={AsyncIncidentReportScreen} />
      <Route path="/error" component={Async500Screen} />
      <Route component={AsyncPage404Screen} />
    </Switch>
  </ConnectedRouter>
);

export default Routes;
