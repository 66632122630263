"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_SUCCESS = exports.UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_REQUEST = exports.UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_FAIL = exports.TOGGLE_SCAN_OUT_REQUIRED_SUCCESS = exports.TOGGLE_SCAN_OUT_REQUIRED_REQUEST = exports.TOGGLE_SCAN_OUT_REQUIRED_FAIL = exports.TOGGLE_CREDIT_CARD_SUCCESS = exports.TOGGLE_CREDIT_CARD_REQUEST = exports.TOGGLE_CREDIT_CARD_FAIL = exports.RESET_STATE_DATA = exports.LIST_PRICER_BY_CONDO_ID_SUCCESS = exports.LIST_PRICER_BY_CONDO_ID_REQUEST = exports.LIST_PRICER_BY_CONDO_ID_FAIL = exports.ENABLE_FEATURES_SUCCESS = exports.ENABLE_FEATURES_REQUEST = exports.ENABLE_FEATURES_FAIL = exports.EDIT_CURRENT_USER_SUCCESS = exports.EDIT_CURRENT_USER_REQUEST = exports.EDIT_CURRENT_USER_FAIL = exports.DISABLE_FEATURES_SUCCESS = exports.DISABLE_FEATURES_REQUEST = exports.DISABLE_FEATURES_FAIL = exports.CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_SUCCESS = exports.CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_REQUEST = exports.CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_FAIL = exports.CHANGE_STAFF_PASSWORD_SUCCESS = exports.CHANGE_STAFF_PASSWORD_REQUEST = exports.CHANGE_STAFF_PASSWORD_FAIL = void 0;
var CHANGE_STAFF_PASSWORD_REQUEST = exports.CHANGE_STAFF_PASSWORD_REQUEST = '@Settings/CHANGE_STAFF_PASSWORD_REQUEST';
var CHANGE_STAFF_PASSWORD_SUCCESS = exports.CHANGE_STAFF_PASSWORD_SUCCESS = '@Settings/CHANGE_STAFF_PASSWORD_SUCCESS';
var CHANGE_STAFF_PASSWORD_FAIL = exports.CHANGE_STAFF_PASSWORD_FAIL = '@Settings/CHANGE_STAFF_PASSWORD_FAIL';
var EDIT_CURRENT_USER_REQUEST = exports.EDIT_CURRENT_USER_REQUEST = '@Settings/EDIT_CURRENT_USER_REQUEST';
var EDIT_CURRENT_USER_SUCCESS = exports.EDIT_CURRENT_USER_SUCCESS = '@Settings/EDIT_CURRENT_USER_SUCCESS';
var EDIT_CURRENT_USER_FAIL = exports.EDIT_CURRENT_USER_FAIL = '@Settings/EDIT_CURRENT_USER_FAIL';
var RESET_STATE_DATA = exports.RESET_STATE_DATA = '@Settings/RESET_STATE_DATA';
var LIST_PRICER_BY_CONDO_ID_REQUEST = exports.LIST_PRICER_BY_CONDO_ID_REQUEST = '@Settings/LIST_PRICER_BY_CONDO_ID_REQUEST';
var LIST_PRICER_BY_CONDO_ID_SUCCESS = exports.LIST_PRICER_BY_CONDO_ID_SUCCESS = '@Settings/LIST_PRICER_BY_CONDO_ID_SUCCESS';
var LIST_PRICER_BY_CONDO_ID_FAIL = exports.LIST_PRICER_BY_CONDO_ID_FAIL = '@Settings/LIST_PRICER_BY_CONDO_ID_FAIL';
var CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_REQUEST = exports.CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_REQUEST = '@Settings/CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_REQUEST';
var CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_SUCCESS = exports.CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_SUCCESS = '@Settings/CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_SUCCESS';
var CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_FAIL = exports.CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_FAIL = '@Settings/CREATE_INTERVAL_PRICE_TABLE_ON_PRICER_FAIL';
var UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_REQUEST = exports.UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_REQUEST = '@Settings/UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_REQUEST';
var UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_SUCCESS = exports.UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_SUCCESS = '@Settings/UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_SUCCESS';
var UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_FAIL = exports.UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_FAIL = '@Settings/UPDATE_INTERVAL_PRICE_TABLE_ON_PRICER_FAIL';
var ENABLE_FEATURES_REQUEST = exports.ENABLE_FEATURES_REQUEST = '@Settings/ENABLE_FEATURES_REQUEST';
var ENABLE_FEATURES_SUCCESS = exports.ENABLE_FEATURES_SUCCESS = '@Settings/ENABLE_FEATURES_SUCCESS';
var ENABLE_FEATURES_FAIL = exports.ENABLE_FEATURES_FAIL = '@Settings/ENABLE_FEATURES_FAIL';
var DISABLE_FEATURES_REQUEST = exports.DISABLE_FEATURES_REQUEST = '@Settings/DISABLE_FEATURES_REQUEST';
var DISABLE_FEATURES_SUCCESS = exports.DISABLE_FEATURES_SUCCESS = '@Settings/DISABLE_FEATURES_SUCCESS';
var DISABLE_FEATURES_FAIL = exports.DISABLE_FEATURES_FAIL = '@Settings/DISABLE_FEATURES_FAIL';
var TOGGLE_SCAN_OUT_REQUIRED_REQUEST = exports.TOGGLE_SCAN_OUT_REQUIRED_REQUEST = '@Settings/TOGGLE_SCAN_OUT_REQUIRED_REQUEST';
var TOGGLE_SCAN_OUT_REQUIRED_SUCCESS = exports.TOGGLE_SCAN_OUT_REQUIRED_SUCCESS = '@Settings/TOGGLE_SCAN_OUT_REQUIRED_SUCCESS';
var TOGGLE_SCAN_OUT_REQUIRED_FAIL = exports.TOGGLE_SCAN_OUT_REQUIRED_FAIL = '@Settings/TOGGLE_SCAN_OUT_REQUIRED_FAIL';
var TOGGLE_CREDIT_CARD_REQUEST = exports.TOGGLE_CREDIT_CARD_REQUEST = '@Settings/TOGGLE_CREDIT_CARD_REQUEST';
var TOGGLE_CREDIT_CARD_SUCCESS = exports.TOGGLE_CREDIT_CARD_SUCCESS = '@Settings/TOGGLE_CREDIT_CARD_SUCCESS';
var TOGGLE_CREDIT_CARD_FAIL = exports.TOGGLE_CREDIT_CARD_FAIL = '@Settings/TOGGLE_CREDIT_CARD_FAIL';