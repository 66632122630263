"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  loadingPatchPackage: false,
  errorListPackages: false,
  loadingListPackages: false,
  errorListOvertimePackages: false,
  loadingListOvertimePackages: false,
  errorListOvertimePackagesToClear: false,
  loadingListOvertimePackagesToClear: false,
  loadingPackage: false,
  errorGetPackage: false,
  loadingChangePackageLocation: false,
  errorChangePackageLocation: false,
  errorListLocations: false,
  loadingListLocations: false,
  loadingCreateLocation: false,
  errorCreateLocation: false,
  loadingDeleteLocation: false,
  errorDeleteLocation: false,
  errorReturningPackage: false,
  loadingReturningPackage: false,
  errorUndoReturnPackage: false,
  loadingUndoReturnPackage: false,
  loadingDeletePackage: false,
  errorDeletePackage: false,
  errorListCarriers: false,
  loadingListCarriers: false,
  carriersList: [],
  errorChangeCarrier: false,
  loadingChangeCarrier: false,
  loadingForcePickup: false,
  errorForcePickup: false,
  loadingCreateBatch: false,
  errorCreateBatch: false,
  loadingPackageTimeline: false,
  errorPackageTimeline: false,
  packageTimelineData: [],
  batchResult: {},
  listCount: 0,
  listPages: 0,
  overtimePackagesListCount: 0,
  overtimePackagesListPages: 0,
  list: [],
  overtimePackagesList: [],
  overtimePackagesToClearListCount: 0,
  overtimePackagesToClearListPages: 0,
  overtimePackagesToClearList: [],
  locationsList: [],
  selectedPackage: {},
  listPackageMap: [],
  loadingPackageMap: false,
  packagesByCarrier: [],
  packagesByDay: [],
  packagesByState: [],
  loadingPackagesByState: false,
  loadingPackagesByCarrier: false,
  loadingPackagesByDay: false,
  errorPackagesByState: false,
  errorPackagesByCarrier: false,
  errorPackagesByDay: false,
  listOvertimeFilters: [],
  errorOvertimeFilters: false,
  loadingOvertimeFilters: false,
  listIncomingPackagesInClearOvertime: [],
  errorSetIncomingPackagesInClearOvertime: false,
  loadingIncomingPackagesInClearOvertime: false,
  listIncomingPackagesInWaitingResident: [],
  errorSetIncomingPackagesInWaitingResident: false,
  loadingIncomingPackagesInWaitingResident: false
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7, _action$payload$data8, _action$payload8, _action$payload$data9, _action$payload9, _action$payload$data10, _action$payload10, _action$payload$data11, _action$payload11, _action$payload$data12, _action$payload12, _action$payload$data13, _action$payload13, _action$payload$data14, _action$payload14, _action$payload$data15, _action$payload15, _action$payload$data16, _action$payload16, _action$payload$data17, _action$payload17, _action$payload$data18, _action$payload18, _action$payload$data19, _action$payload19, _action$payload$data20, _action$payload20, _action$payload$data21, _action$payload21, _action$payload$data22, _action$payload22, _action$payload$data23, _action$payload23, _action$payload$data24, _action$payload24, _action$payload$data25, _action$payload25, _action$payload$data26, _action$payload26, _action$payload$data27, _action$payload27, _action$payload$data28, _action$payload28, _action$payload$data29, _action$payload29, _action$payload$data30, _action$payload30, _action$payload$data31, _action$payload31, _action$payload$data32, _action$payload32, _action$payload$data33, _action$payload33, _action$payload$data34, _action$payload34, _action$payload$data35, _action$payload35, _action$payload$data36, _action$payload36, _action$payload$data37, _action$payload37, _action$payload$data38, _action$payload38;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.LIST_PACKAGE_MAP_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackageMap: true
      });
    case _variables.LIST_PACKAGE_MAP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackageMap: false,
        error: false,
        listPackageMap: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : []
      });
    case _variables.LIST_PACKAGE_MAP_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackageMap: false,
        error: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.LIST_OVERTIME_FILTERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingOvertimeFilters: true
      });
    case _variables.LIST_OVERTIME_FILTERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingOvertimeFilters: false,
        errorOvertimeFilters: false,
        listOvertimeFilters: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : []
      });
    case _variables.LIST_OVERTIME_FILTERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingOvertimeFilters: false,
        errorOvertimeFilters: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {}
      });
    case _variables.SET_IN_CLEAR_OVERTIME_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingIncomingPackagesInClearOvertime: true
      });
    case _variables.SET_IN_CLEAR_OVERTIME_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingIncomingPackagesInClearOvertime: false,
        errorSetIncomingPackagesInClearOvertime: false,
        listIncomingPackagesInClearOvertime: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : []
      });
    case _variables.SET_IN_CLEAR_OVERTIME_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingIncomingPackagesInClearOvertime: false,
        errorSetIncomingPackagesInClearOvertime: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {}
      });
    case _variables.SET_IN_WAITING_RESIDENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingIncomingPackagesInWaitingResident: true
      });
    case _variables.SET_IN_WAITING_RESIDENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingIncomingPackagesInWaitingResident: false,
        errorSetIncomingPackagesInWaitingResident: false,
        listIncomingPackagesInWaitingResident: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : []
      });
    case _variables.SET_IN_WAITING_RESIDENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingIncomingPackagesInWaitingResident: false,
        errorSetIncomingPackagesInWaitingResident: (_action$payload$data8 = action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.data) !== null && _action$payload$data8 !== void 0 ? _action$payload$data8 : {}
      });
    case _variables.CREATE_BATCH_PACKAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateBatch: true
      });
    case _variables.CREATE_BATCH_PACKAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateBatch: false,
        errorCreateBatch: false,
        batchResult: (_action$payload$data9 = action === null || action === void 0 || (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.data) !== null && _action$payload$data9 !== void 0 ? _action$payload$data9 : {}
      });
    case _variables.CREATE_BATCH_PACKAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateBatch: false,
        errorCreateBatch: (_action$payload$data10 = action === null || action === void 0 || (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.data) !== null && _action$payload$data10 !== void 0 ? _action$payload$data10 : {}
      });
    case _variables.FORCE_PICKUP_PACKAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingForcePickup: true
      });
    case _variables.FORCE_PICKUP_PACKAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingForcePickup: false,
        errorForcePickup: false
      });
    case _variables.FORCE_PICKUP_PACKAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingForcePickup: false,
        errorForcePickup: (_action$payload$data11 = action === null || action === void 0 || (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.data) !== null && _action$payload$data11 !== void 0 ? _action$payload$data11 : {}
      });
    case _variables.UNDO_RETURN_PACKAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingUndoReturnPackage: true
      });
    case _variables.UNDO_RETURN_PACKAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingUndoReturnPackage: false,
        errorUndoReturnPackage: false
      });
    case _variables.UNDO_RETURN_PACKAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingUndoReturnPackage: false,
        errorUndoReturnPackage: (_action$payload$data12 = action === null || action === void 0 || (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.data) !== null && _action$payload$data12 !== void 0 ? _action$payload$data12 : {}
      });
    case _variables.CHANGE_PACKAGE_LOCATION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingChangePackageLocation: true
      });
    case _variables.CHANGE_PACKAGE_LOCATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingChangePackageLocation: false,
        errorChangePackageLocation: false
      });
    case _variables.CHANGE_PACKAGE_LOCATION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingChangePackageLocation: false,
        errorChangePackageLocation: (_action$payload$data13 = action === null || action === void 0 || (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : _action$payload13.data) !== null && _action$payload$data13 !== void 0 ? _action$payload$data13 : {}
      });
    case _variables.PATCH_PACKAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPatchPackage: true
      });
    case _variables.PATCH_PACKAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPatchPackage: false
      });
    case _variables.PATCH_PACKAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPatchPackage: false
      });
    case _variables.CHANGE_PACKAGE_CARRIER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingChangeCarrier: true
      });
    case _variables.CHANGE_PACKAGE_CARRIER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingChangeCarrier: false,
        errorChangeCarrier: false
      });
    case _variables.CHANGE_PACKAGE_CARRIER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingChangeCarrier: false,
        errorChangeCarrier: (_action$payload$data14 = action === null || action === void 0 || (_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : _action$payload14.data) !== null && _action$payload$data14 !== void 0 ? _action$payload$data14 : {}
      });
    case _variables.RETURN_PACKAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingReturningPackage: true
      });
    case _variables.RETURN_PACKAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingReturningPackage: false,
        errorReturningPackage: false
      });
    case _variables.RETURN_PACKAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingReturningPackage: false,
        errorReturningPackage: (_action$payload$data15 = action === null || action === void 0 || (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : _action$payload15.data) !== null && _action$payload$data15 !== void 0 ? _action$payload$data15 : {}
      });
    case _variables.DELETE_PACKAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeletePackage: true
      });
    case _variables.DELETE_PACKAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeletePackage: false,
        errorDeletePackage: false
      });
    case _variables.DELETE_PACKAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeletePackage: false,
        errorDeletePackage: (_action$payload$data16 = action === null || action === void 0 || (_action$payload16 = action.payload) === null || _action$payload16 === void 0 ? void 0 : _action$payload16.data) !== null && _action$payload$data16 !== void 0 ? _action$payload$data16 : {}
      });
    case _variables.LIST_PACKAGE_TIMELINE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackageTimeline: true
      });
    case _variables.LIST_PACKAGE_TIMELINE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackageTimeline: false,
        errorPackageTimeline: false,
        packageTimelineData: (_action$payload$data17 = action === null || action === void 0 || (_action$payload17 = action.payload) === null || _action$payload17 === void 0 ? void 0 : _action$payload17.data) !== null && _action$payload$data17 !== void 0 ? _action$payload$data17 : []
      });
    case _variables.LIST_PACKAGE_TIMELINE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackageTimeline: false,
        errorPackageTimeline: (_action$payload$data18 = action === null || action === void 0 || (_action$payload18 = action.payload) === null || _action$payload18 === void 0 ? void 0 : _action$payload18.data) !== null && _action$payload$data18 !== void 0 ? _action$payload$data18 : {},
        packageTimelineData: []
      });
    case _variables.LIST_LOCATIONS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListLocations: true
      });
    case _variables.LIST_LOCATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListLocations: false,
        errorListLocations: false,
        locationsList: (_action$payload$data19 = action === null || action === void 0 || (_action$payload19 = action.payload) === null || _action$payload19 === void 0 ? void 0 : _action$payload19.data) !== null && _action$payload$data19 !== void 0 ? _action$payload$data19 : []
      });
    case _variables.LIST_LOCATIONS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListLocations: false,
        errorListLocations: (_action$payload$data20 = action === null || action === void 0 || (_action$payload20 = action.payload) === null || _action$payload20 === void 0 ? void 0 : _action$payload20.data) !== null && _action$payload$data20 !== void 0 ? _action$payload$data20 : {},
        locationsList: []
      });
    case _variables.CREATE_LOCATION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateLocation: true
      });
    case _variables.CREATE_LOCATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateLocation: false,
        errorCreateLocation: false
      });
    case _variables.CREATE_LOCATION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCreateLocation: false,
        errorCreateLocation: (_action$payload$data21 = action === null || action === void 0 || (_action$payload21 = action.payload) === null || _action$payload21 === void 0 ? void 0 : _action$payload21.data) !== null && _action$payload$data21 !== void 0 ? _action$payload$data21 : {}
      });
    case _variables.DELETE_LOCATION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteLocation: true
      });
    case _variables.DELETE_LOCATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteLocation: false,
        errorDeleteLocation: false
      });
    case _variables.DELETE_LOCATION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDeleteLocation: false,
        errorDeleteLocation: (_action$payload$data22 = action === null || action === void 0 || (_action$payload22 = action.payload) === null || _action$payload22 === void 0 ? void 0 : _action$payload22.data) !== null && _action$payload$data22 !== void 0 ? _action$payload$data22 : {}
      });
    case _variables.LIST_CARRIERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListCarriers: true
      });
    case _variables.LIST_CARRIERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListCarriers: false,
        errorListCarriers: false,
        carriersList: (_action$payload$data23 = action === null || action === void 0 || (_action$payload23 = action.payload) === null || _action$payload23 === void 0 ? void 0 : _action$payload23.data) !== null && _action$payload$data23 !== void 0 ? _action$payload$data23 : []
      });
    case _variables.LIST_CARRIERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListCarriers: false,
        errorListCarriers: (_action$payload$data24 = action === null || action === void 0 || (_action$payload24 = action.payload) === null || _action$payload24 === void 0 ? void 0 : _action$payload24.data) !== null && _action$payload$data24 !== void 0 ? _action$payload$data24 : {},
        carriersList: []
      });
    case _variables.LIST_PACKAGES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListPackages: true
      });
    case _variables.LIST_PACKAGES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListPackages: false,
        errorListPackages: false,
        list: (_action$payload$data25 = action === null || action === void 0 || (_action$payload25 = action.payload) === null || _action$payload25 === void 0 ? void 0 : _action$payload25.data) !== null && _action$payload$data25 !== void 0 ? _action$payload$data25 : [],
        listCount: action.payload.headers['total-count'],
        listPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_PACKAGES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListPackages: false,
        errorListPackages: (_action$payload$data26 = action === null || action === void 0 || (_action$payload26 = action.payload) === null || _action$payload26 === void 0 ? void 0 : _action$payload26.data) !== null && _action$payload$data26 !== void 0 ? _action$payload$data26 : {},
        list: []
      });
    case _variables.LIST_OVERTIME_PACKAGES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListOvertimePackages: true
      });
    case _variables.LIST_OVERTIME_PACKAGES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListOvertimePackages: false,
        errorListOvertimePackages: false,
        overtimePackagesList: (_action$payload$data27 = action === null || action === void 0 || (_action$payload27 = action.payload) === null || _action$payload27 === void 0 ? void 0 : _action$payload27.data) !== null && _action$payload$data27 !== void 0 ? _action$payload$data27 : [],
        overtimePackagesListCount: action.payload.headers['total-count'],
        overtimePackagesListPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_OVERTIME_PACKAGES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListOvertimePackages: false,
        errorListOvertimePackages: (_action$payload$data28 = action === null || action === void 0 || (_action$payload28 = action.payload) === null || _action$payload28 === void 0 ? void 0 : _action$payload28.data) !== null && _action$payload$data28 !== void 0 ? _action$payload$data28 : {},
        overtimePackagesList: []
      });
    case _variables.LIST_OVERTIME_PACKAGES_TO_CLEAR_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListOvertimePackagesToClear: true
      });
    case _variables.LIST_OVERTIME_PACKAGES_TO_CLEAR_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListOvertimePackagesToClear: false,
        errorListOvertimePackagesToClear: false,
        overtimePackagesToClearList: (_action$payload$data29 = action === null || action === void 0 || (_action$payload29 = action.payload) === null || _action$payload29 === void 0 ? void 0 : _action$payload29.data) !== null && _action$payload$data29 !== void 0 ? _action$payload$data29 : [],
        overtimePackagesToClearListCount: action.payload.headers['total-count'],
        overtimePackagesToClearListPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_OVERTIME_PACKAGES_TO_CLEAR_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListOvertimePackagesToClear: false,
        errorListOvertimePackagesToClear: (_action$payload$data30 = action === null || action === void 0 || (_action$payload30 = action.payload) === null || _action$payload30 === void 0 ? void 0 : _action$payload30.data) !== null && _action$payload$data30 !== void 0 ? _action$payload$data30 : {},
        overtimePackagesToClearList: []
      });
    case _variables.GET_PACKAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackage: true
      });
    case _variables.GET_PACKAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackage: false,
        errorGetPackage: false,
        selectedPackage: (_action$payload$data31 = action === null || action === void 0 || (_action$payload31 = action.payload) === null || _action$payload31 === void 0 ? void 0 : _action$payload31.data) !== null && _action$payload$data31 !== void 0 ? _action$payload$data31 : {}
      });
    case _variables.GET_PACKAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackage: false,
        errorGetPackage: (_action$payload$data32 = action === null || action === void 0 || (_action$payload32 = action.payload) === null || _action$payload32 === void 0 ? void 0 : _action$payload32.data) !== null && _action$payload$data32 !== void 0 ? _action$payload$data32 : {},
        selectedPackage: {}
      });
    case _variables.GET_PACKAGES_BY_CARRIER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackagesByCarrier: true,
        errorPackagesByCarrier: false
      });
    case _variables.GET_PACKAGES_BY_CARRIER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackagesByCarrier: false,
        packagesByCarrier: (_action$payload$data33 = action === null || action === void 0 || (_action$payload33 = action.payload) === null || _action$payload33 === void 0 ? void 0 : _action$payload33.data) !== null && _action$payload$data33 !== void 0 ? _action$payload$data33 : []
      });
    case _variables.GET_PACKAGES_BY_CARRIER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackagesByCarrier: false,
        errorPackagesByCarrier: (_action$payload$data34 = action === null || action === void 0 || (_action$payload34 = action.payload) === null || _action$payload34 === void 0 ? void 0 : _action$payload34.data) !== null && _action$payload$data34 !== void 0 ? _action$payload$data34 : {}
      });
    case _variables.GET_PACKAGES_BY_DAY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackagesByDay: true,
        errorPackagesByDay: false
      });
    case _variables.GET_PACKAGES_BY_DAY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackagesByDay: false,
        packagesByDay: (_action$payload$data35 = action === null || action === void 0 || (_action$payload35 = action.payload) === null || _action$payload35 === void 0 ? void 0 : _action$payload35.data) !== null && _action$payload$data35 !== void 0 ? _action$payload$data35 : []
      });
    case _variables.GET_PACKAGES_BY_DAY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackagesByDay: false,
        errorPackagesByDay: (_action$payload$data36 = action === null || action === void 0 || (_action$payload36 = action.payload) === null || _action$payload36 === void 0 ? void 0 : _action$payload36.data) !== null && _action$payload$data36 !== void 0 ? _action$payload$data36 : {}
      });
    case _variables.GET_PACKAGES_BY_STATE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackagesByState: true,
        errorPackagesByState: false
      });
    case _variables.GET_PACKAGES_BY_STATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackagesByState: false,
        packagesByState: (_action$payload$data37 = action === null || action === void 0 || (_action$payload37 = action.payload) === null || _action$payload37 === void 0 ? void 0 : _action$payload37.data) !== null && _action$payload$data37 !== void 0 ? _action$payload$data37 : []
      });
    case _variables.GET_PACKAGES_BY_STATE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPackagesByState: false,
        errorPackagesByState: (_action$payload$data38 = action === null || action === void 0 || (_action$payload38 = action.payload) === null || _action$payload38 === void 0 ? void 0 : _action$payload38.data) !== null && _action$payload$data38 !== void 0 ? _action$payload$data38 : {}
      });
    default:
      return state;
  }
};