import React, { useEffect, useState } from 'react';
import { Button } from '@qwkin/components';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';

import { extractCondoInfoFromArray, extractUserDataFromState } from '../../utils';
// eslint-disable-next-line import/no-cycle
import { WrappedRow } from '../../styles';
import Sidemenu from '../sidemenu';
import { AppContent, LogoContainer, NavbarContent, PlainNavbar } from './styles';
import UserCard from './userCard';
import CondoInfo from './condoInfo';

export const AppFeatures = Object.freeze({
  dashboard: 0,
  gateFeed: 1,
  units: 2,
  contractors: 3,
  amenities: 4,
  packages: 5,
  reports: 6,
  settings: 7,
  valet: 8,
  community: 9,
  workOrders: 10,
  documents: 11,
  invoice: 12,
  incidents: 13,
});

const AppContainer = ({
  children,
  useGoBackNavigation,
  activeAppFeature = AppFeatures.dashboard,
  isFullScreen = false,
}) => {
  const dispatch = useDispatch();
  const { adminCondos, masterCondo } = useSelector(extractUserDataFromState);
  const [masterCondoInfo, setMasterCondoInfo] = useState(null);

  const handleNavbarButton = () => {
    if (useGoBackNavigation) {
      dispatch(goBack());
    }
  };

  useEffect(() => {
    setMasterCondoInfo(extractCondoInfoFromArray(masterCondo, adminCondos));
  }, [adminCondos, masterCondo]);

  return (
    <div>
      {!isFullScreen && (
        <PlainNavbar>
          <NavbarContent>
            <LogoContainer>
              {useGoBackNavigation && (
                <div style={{ transition: 'all ease .25s' }}>
                  <Button dense icon="arrow-left" onClick={handleNavbarButton}>
                    {useGoBackNavigation}
                  </Button>
                </div>
              )}
            </LogoContainer>
            <CondoInfo condo={masterCondoInfo} />
            <UserCard />
          </NavbarContent>
        </PlainNavbar>
      )}
      <WrappedRow>
        <Sidemenu
          activeAppFeature={activeAppFeature}
          isFullScreen={isFullScreen}
          condo={masterCondoInfo}
        />
        <AppContent isFullScreen={isFullScreen}>{children}</AppContent>
      </WrappedRow>
    </div>
  );
};

export default AppContainer;
